import React from "react";
import * as Icon from "react-feather";

const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Layout size={20} />,
    navLink: "/",
  },

  //!----------------Services --------
  {
    id: "services",
    title: "Services",
    type: "collapse",
    icon: <Icon.Archive size={15} />,
    children: [
      {
        id: "servicesList",
        title: "Services",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/service/list",
      },
      {
        id: "subServices",
        title: "Subservices",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/sub-service/list",
      },
    ],
  },

  //!----------------Projects--------
  {
    id: "projectsList",
    title: "Projects",
    type: "item",
    icon: <Icon.Box size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/project/list",
  },

  //!----------------Careers --------
  {
    id: "careers",
    title: "Careers",
    type: "collapse",
    icon: <Icon.List size={15} />,
    children: [
      {
        id: "careersList",
        title: "Careers",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/career/list",
      },
      {
        id: "careersCategory",
        title: "Careers Category",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/career-category/list",
      },
    ],
  },

  // !---------------- Testimonials--------
  {
    id: "testimoniallist",
    title: "Testimonials",
    type: "item",
    icon: <Icon.Star size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/testimonial/list",
  },

  //!----------------Blogs--------
  // {
  //   id: "blogs",
  //   title: "Blogs",
  //   type: "collapse",
  //   icon: <Icon.Book size={15} />,
  //   children: [
  //     {
  //       id: "blogList",
  //       title: "Blog",
  //       type: "item",
  //       icon: <Icon.AlertTriangle size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/blog/list",
  //     },
  //     {
  //       id: "blogCategory",
  //       title: "Blog Category",
  //       type: "item",
  //       icon: <Icon.AlignCenter size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/blog-category/list",
  //     },
  //     {
  //       id: "commentslist",
  //       title: "Comments List",
  //       type: "item",
  //       icon: <Icon.Users size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/comments",
  //     },
  //   ],
  // },

  //!----------------News --------
  {
    id: "newsList",
    title: "News",
    type: "item",
    icon: <Icon.FileText size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/news/list",
  },

  //!--------------faqs-------
  {
    id: "FAQSList",
    title: "FAQ's",
    type: "item",
    icon: <Icon.HelpCircle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/faq/list",
  },

  //!----------------contact list--------
  {
    id: "ContactUsFormList",
    title: "Contact Us",
    type: "item",
    icon: <Icon.Phone size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/contact-list",
  },

  //!----------------business corporation list--------
  {
    id: "businessCorporationList",
    title: "Business Corporation",
    type: "collapse",
    icon: <Icon.Users size={15} />,
    children: [
      {
        id: "oilfieldClientsList",
        title: "Oilfield Clients",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/oilfield-clients/list",
      },
      {
        id: "technicalServicesList",
        title: "Product and Technical Services",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/product-technical-services/list",
      },
      {
        id: "agencyList",
        title: "Consulting Service Agency",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/consulting-service-agency/list",
      },
      {
        id: "suppliersList",
        title: "Suppliers",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/suppliers/list",
      },
    ],
  },

  //!----------------Feedback list--------
  {
    id: "feedbackList",
    title: "Feedback",
    type: "collapse",
    icon: <Icon.Star size={15} />,
    children: [
      {
        id: "serviceEvaluationFeedbackList",
        title: "Service Evaluation Feedback",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/service-evaluation-feedback/list",
      },
      {
        id: "groupServicesFeedbackList",
        title: "Services Group’s Overall Service Evaluation Feedback",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/services-groups-overall-service-evaluation-feedback/list",
      },
      {
        id: "productFeedbackList",
        title: "Services Group’s Product Evaluation Feedback",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/services-groups-product-evaluation-feedback/list",
      },
      {
        id: "userExperienceFeedbackList",
        title: "Website User Experience Feedback",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/website-user-experience-feedback/list",
      },
    ],
  },

  // //!----------------subscribers List--------
  // {
  //   id: "SubscribersList",
  //   title: "Subscribers",
  //   type: "item",
  //   icon: <Icon.Users size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/subscribers-list",
  // },

  //!---------------Users------
  // {
  //   id: "users",
  //   title: "Registered Users",
  //   type: "item",
  //   icon: <Icon.Users size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/users-list",
  // },

  //!-------------Gallery--------
  {
    id: "gallery",
    title: "Gallery",
    type: "item",
    icon: <Icon.Image size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/gallery",
  },
];

export default navigationConfig;
